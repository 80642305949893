<template>
  <div>

    <vs-popup :title="$t('New Transaction')" :active.sync="final">

      <vs-row>
        <vs-col class="mb-2 flex" vs-w="6" vs-s="6" vs-xs="12">
          <my-select autocomplete class="selectExample " :label="$t('Category')" @change="checkSubCategory"
            v-model="transaction.transaction_category_id">
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categories" />
          </my-select>

          <span v-if="errors.transaction_category_id"
            class="text-danger text-sm valdation_error_avatar">{{errors.transaction_category_id[0]}}
          </span>

          <font-awesome-icon @click="openAddCategory=true;NewTransactionActiveLocal=false;process=false" class="mt-10 ml-2 cursor"
            color="rgb(var(--vs-primary))" :icon="['fas', 'plus']" />
          <actions-popup v-if="openAddCategory" @close-popup="addCategory" isCategory="yes" />

        </vs-col>


        <vs-col v-if="transaction.transaction_category_id" class=" flex" vs-w="6" vs-s="6" vs-xs="12">
          <my-select autocomplete :placeholder="$t('Select Sub Category')" class="selectExample mt-5" v-model="sub_id">
            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in subcategories" />

          </my-select>
          <font-awesome-icon @click="openAddSubCategory=true;NewTransactionActiveLocal=false;process=false"
          class="mt-7 ml-2 cursor"
            color="rgb(var(--vs-primary))" :icon="['fas', 'plus']" />
          <actions-popup v-if="openAddSubCategory" @close-popup="addCategory"
            :parent="transaction.transaction_category_id" />
        </vs-col>
        <vs-col vs-w="6">
          <my-select class="id" id="id" :label="$t('Transaction Type')" v-model="transaction.link_to">
            <vs-select-item value="income" :text="$t('Income ')" />
            <vs-select-item value="outcome" :text="$t('Outcome ')" />
          </my-select>
          <span v-if="errors.link_to" class="text-danger text-sm valdation_error_avatar">{{errors.link_to[0]}}</span>
        </vs-col>
        <vs-col vs-w="6">
          <vs-input type="datetime-local" color="success" :label="$t('Transaction Date')" v-model="transaction.issue_date" />
          <span v-if="errors.issue_date"
            class="text-danger text-sm valdation_error_avatar">{{errors.issue_date[0]}}</span>
        </vs-col>
        <vs-col vs-w="6">
          <vs-input color="success" :label="$t('Amount')"
            v-currency="{currency: {suffix:' TL'}, locale: 'en',allowNegative:false,  valueRange: { min: 0 },distractionFree: false,}"
            v-model="transaction.amount" />
          <span v-if="errors.amount" class="text-danger text-sm valdation_error_avatar">{{errors.amount[0]}}</span>
        </vs-col>
        <vs-col class="mb-6 mt-3" vs-w="12">
          <vs-textarea :label="$t('Description')" v-model="transaction.description" />
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-align="flex-start" vs-justify="flex-start">
          <vs-button @click="close()" color="danger" type="gradient">{{$t('Cancel')}}</vs-button>
        </vs-col>
        <vs-col vs-w="6" vs-type="flex" vs-align="flex-end" vs-justify="flex-end">
          <vs-button color="success" @click="CreateNewTransaction" type="gradient">{{$t('Create Transaction')}}
          </vs-button>
        </vs-col>
      </vs-row>

    </vs-popup>

  </div>
</template>

<script>
/* eslint-disable */
  import vSelect from 'vue-select'
  import MySelect from '../../../../components/MySelect'
  import ActionsPopup from "@/views/pages/transactionCategory/actionsPopup";

  export default {
    name: "NewTransaction",
    props: ['IsNewTransactionActive'],
    components: {
      vSelect,
      MySelect,
      ActionsPopup,
    },
    data: () => ({
      transaction: {
        transaction_category_id: '',
        link_to: '',
        issue_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-')+'T'+
                    ( new Date().getHours()>9 ? new Date().getHours() : '0'+new Date().getHours() ) +':'+ (new Date().getMinutes()> 9 ?
                    new Date().getMinutes() : '0'+new Date().getMinutes()) ,
        amount: '',
        description: '',
      },
      transaction2: {
        transaction_category_id: '',
        link_to: '',
        issue_date: new Date().toJSON().slice(0, 10).replace(/-/g, '-')+'T'+new Date().getHours()+':'+ (new Date().getMinutes()>9 ? new Date().getMinutes() : '0'+new Date().getMinutes()) ,
        amount: '',
        description: '',
      },
      subcategories: [],
      sub_id: null,
      errors: [],
      openAddCategory: false,
      process: false,
      openAddSubCategory: false
    }),
    watch: {
      categories(val) {
        this.checkSubCategory()
      }
    },
    computed: {
      final: {
        get() {
          return this.NewTransactionActiveLocal || this.process ? true : false
        },
        set(val) {
          if (!val) {
            this.process=false
            this.$emit('closePopup')
          }
        }
      },
      categories() {
        return this.$store.getters["transactionCategory/data"]
      },
      NewTransactionActiveLocal: {
        get() {
          return this.IsNewTransactionActive
        },
        set(val) {
          if (!val) {
            this.$emit('closePopup')
          }
        }
      },
    },
    methods: {
      addCategory() {
        this.$store.dispatch("transactionCategory/GetData", {
          nopaginate: true
        }).then(() => {
          this.openAddCategory = false
          this.openAddSubCategory = false
          this.process = true
        })
      },
      checkSubCategory() {
        if(this.transaction.transaction_category_id){
          this.subcategories = this.categories.find(x => x.id === this.transaction.transaction_category_id).parents
          this.sub_id = null
        }
      },
      close() {
        this.process=false
        this.NewTransactionActiveLocal = false
      },
      CreateNewTransaction() {
        this.transaction.amount = this.transaction.amount.replaceAll(',', '').replace(' TL', '')
        let finalData = {
          ...this.transaction
        }
        if (this.sub_id) {
          finalData.transaction_category_id = this.sub_id
        }
        this.$http.post(`account/newTransaction/${this.$route.params.accountId}`, finalData).then((response) => {
          this.NewTransactionActiveLocal = false
          this.process = false
          this.$vs.notify({
            title: 'Success',
            text: this.$t('New Transaction has been created successfully'),
            color: 'success',
            position: 'top-center'
          })
          this.transaction=this.transaction2
          this.$store.dispatch('account/getAccountTransaction', {
            account_id: this.$route.params.accountId
          })
        })
      }
    },
    created() {
      this.$store.dispatch("transactionCategory/SetSectionName", {
        'sectionName': "transaction_categories"
      })
      this.$store.dispatch("transactionCategory/GetData", {
        nopaginate: true
      })
      this.$store.dispatch("account/GetData", {
        nopaginate: true
      })
      this.$store.dispatch("cashier/GetData", {
        nopaginate: true
      })

    }

  }

</script>
<style >
  .cursor {
    cursor: pointer;
  }

  .mt-7 {
    margin-top: 2.0rem !important;
  }

  .id{
    opacity: 1 !important;
  }

  #id{
    opacity: 1 !important;
  }

</style>
